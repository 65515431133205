<template>
	<div>Redirecting to login page..</div>
</template>

<script>
import logout from '@/mixins/logout'
export default {
	mixins: [logout],
	created() {
		this.logout()
	},
}
</script>
